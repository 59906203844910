import { Injectable } from '@angular/core'
import { BaseAccessConfigProvider, IAccessConfig } from '@engineering11/access-web'
import { isNotNil } from '@engineering11/utility'
import { Store } from '@ngrx/store'
import { distinctUntilChanged, filter, map } from 'rxjs/operators'
import { selectors } from '../store/selectors'

@Injectable({ providedIn: 'root' })
export class AccessConfigProvider extends BaseAccessConfigProvider {
  private roles$ = this.store.select(selectors.getCurrentUser).pipe(
    filter(isNotNil),
    map(user => user.roles),
    distinctUntilChanged()
  )
  private products$ = this.store.select(selectors.getCurrentTokenDecoded).pipe(
    filter(isNotNil),
    map(token => token.products),
    distinctUntilChanged()
  )
  constructor(private store: Store) {
    super()
  }
  get config(): IAccessConfig {
    return {
      roles$: this.roles$,
      products$: this.products$,
      userId$: this.store.select(selectors.getCurrentUserId),
      useLimitDefaults: {},
      roleDisplayConfig: { allRoles: [], roleDescriptionMap: {}, roleLabelMap: {} },
    }
  }
}
