<div
  [e11ScrollBar]="{ top: true, right: true, bottom: true }"
  scrollBarTrackClass="e11-z-10"
  scrollBarThumbClass="e11-bg-skin-primary/20 hover:e11-bg-skin-primary-accent"
  class="e11-py-4 e11-px-2"
>
  <div class="e11-min-h-[800px] e11-scrollable-content e11-max-h-[800px] e11-overflow-auto">
    <div class="e11-w-full e11-min-h-[800px] e11-flex e11-flex-row">
      <ng-content select="swimlane-column"></ng-content>

      <div *ngIf="!activeColumnCount" class="e11-w-full e11-flex e11-justify-center e11-items-center e11-flex-col e11-border-none">
        <p class="e11-mb-0">{{ "You haven't selected any column" }}</p>
        <p>{{ 'Select one or more columns to view in swimlane' }}</p>
        <swimlane-control></swimlane-control>
      </div>
    </div>
  </div>
</div>
