export interface INotificationConfig {
  emailNotification: boolean
  pushNotification: boolean
  inAppNotification: boolean
}

export interface IJobNotificationSettings {
  jobPostClosure: INotificationConfig
  candidateRejection: INotificationConfig
}

export const DEFAULT_JOB_NOTIFICATION_VALUE: INotificationConfig = {
  emailNotification: true,
  pushNotification: true,
  inAppNotification: true,
}
