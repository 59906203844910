import { Injectable } from '@angular/core'
import { BaseConfigProvider, IConfig } from '@engineering11/config-web'
import { Store } from '@ngrx/store'
import { NOT_FOUND_ROUTE } from '../constants'
import { sharedSelectors } from '../store'

@Injectable({ providedIn: 'root' })
export class ConfigProvider extends BaseConfigProvider {
  constructor(private store: Store) {
    super()
  }
  /**
   * Attempting new pattern where all config is included in config provider
   * @returns
   */
  get config(): IConfig {
    return {
      notFoundRoute: NOT_FOUND_ROUTE,
      userId$: this.store.select(sharedSelectors.getCurrentUserId),
    }
  }
}
