import { Inject, Injectable } from '@angular/core'
import { BaseNotificationConfigProvider, INotificationsConfig } from '@engineering11/notifications-web'
import { Store } from '@ngrx/store'
import { ENVIRONMENT } from '../constants'
import { IEnvironment } from '../model/environment.model'
import { sharedSelectors } from '../store'

@Injectable({ providedIn: 'root' })
export class NotificationConfigProvider implements BaseNotificationConfigProvider {
  constructor(private store: Store, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

  get config(): INotificationsConfig {
    return {
      userId: this.store.select(sharedSelectors.getCurrentUserId),
      notificationsBaseURL: this.environment.services.notifications, // TODO: Fill in
      token$: this.store.select(sharedSelectors.getCurrentToken),
    }
  }
}
