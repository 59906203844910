import { Inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { IAuthService } from '@engineering11/auth-web'
import { DateService } from '@engineering11/date-time'
import { DEFAULT_DEACTIVATED_PATH } from '@engineering11/registration-web'
import { Timestamp } from '@engineering11/types'
import { UserType } from '@engineering11/user-shared'
import { userEffects as InheritedUserEffects, IUserService, userActions } from '@engineering11/user-web'
import { isDeepEqual, isNotNil, pick, renameKeys } from '@engineering11/utility'
import { E11Logger, ERROR_TRACKER_TOKEN, IErrorTracker } from '@engineering11/web-api-error'
import { AnalyticsService } from '@engineering11/web-api-firebase'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { snakeCase } from 'lodash'
import { catchError, distinctUntilChanged, filter, map, mergeMap, of, tap, withLatestFrom } from 'rxjs'
import { CnectUserConfigProvider } from '../../config/user.config'
import { ICnectUser } from '../../model/interfaces'
import { CookieService } from '../../service/cookie.service'
import { LocalStorageService } from '../../service/local-storage.service'
import { SessionStorageService } from '../../service/session-storage.service'
import { TokenStorage } from '../../service/token-storage.service'
import { userSelectors } from './user.selectors'

@Injectable()
export class UserEffects extends InheritedUserEffects.UserEffects {
  protected authService: IAuthService = this.userConfigProvider.authService

  constructor(
    protected actions$: Actions,
    private tokenStorage: TokenStorage,
    protected cookieStorage: CookieService,
    //private authService: IAuthService,
    protected userConfigProvider: CnectUserConfigProvider,
    protected userService: IUserService,
    protected sessionStorageService: SessionStorageService,
    protected localStorage: LocalStorageService,
    private analyticsService: AnalyticsService,
    protected logger: E11Logger,
    private store: Store,
    public router: Router,
    @Inject(ERROR_TRACKER_TOKEN) protected errorTracker: IErrorTracker
  ) {
    super(actions$, localStorage, cookieStorage, userConfigProvider, userService, sessionStorageService, logger, router, errorTracker)
  }

  onInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.OnInitUser),
      mergeMap(action =>
        this.authService.onIdTokenChange().pipe(
          distinctUntilChanged(),
          map(token => userActions.OnIdTokenChanged({ token }))
        )
      )
    )
  })

  onInitSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.OnInitUserSuccess),
      mergeMap(action => {
        if (action.appUserId) {
          return this.userService.getUserByIdValueChange(action.appUserId).pipe(
            tap(response => {
              // this will kick the user out if it is disabled remotely
              if (response && response.isDisabled) {
                this.authService.signOut(`/#/${DEFAULT_DEACTIVATED_PATH}`)
              }
            }),
            distinctUntilChanged(isDeepEqual),
            map((response: Timestamp<ICnectUser> | undefined) => {
              const user = response ? response : null
              return userActions.OnUserDocumentChanged({ user })
            }),
            catchError(error => of(userActions.ErrorAction({ payload: error })))
          )
        } else {
          return of(userActions.NoAction({}))
        }
      })
    )
  )

  onUserDocumentChanged$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.OnUserDocumentChanged),
        tap(action => this.tokenStorage.setItem('user', action.user)),
        withLatestFrom(this.store.select(userSelectors.getTokenDecoded).pipe(filter(isNotNil))), // ? Can a race condition happen here?
        map(([action, token]) => {
          const jwtIssuedAt = DateService.toDate(token.iat * 1000) // issue time of the token currently used - iat is in seconds since 1970
          const jwtUpdatedAt = action.user?.jwtUpdatedAt // time the claims were last updated by the backend
          if (!jwtUpdatedAt) return action
          if (jwtIssuedAt < jwtUpdatedAt) {
            this.logger.log('Fetching new JWT', { jwtIssuedAt, jwtUpdatedAt })
            this.authService.getIdToken(true)
          }
          return action
        })
      ),
    { dispatch: false }
  )

  onLogIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.OnLogIn),
      mergeMap(action => {
        this.tokenStorage.setAccessToken(action.token)
        return this.userService.getUserById(action.appUserId).pipe(
          map((response: Timestamp<ICnectUser> | undefined) => {
            this.tokenStorage.setItem('user', JSON.stringify(response))
            if (response) {
              return userActions.OnLogInSuccess({ currentUser: response, redirectPath: action.redirectPath })
            } else {
              return userActions.ErrorAction({ payload: response })
            }
          }),
          catchError(error => of(userActions.ErrorAction({ payload: error })))
        )
      })
    )
  )

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.OnLogInSuccess),
        mergeMap(action => {
          this.setAnalyticsPropertiesForUser(action.currentUser)
          if (action.currentUser.userType === UserType.Consumer) {
            this.router.navigate([action.redirectPath || 'profile'])
          } else {
            this.router.navigate([action.redirectPath || 'home'])
          }
          return of(true)
        })
      ),
    { dispatch: false }
  )

  onLogOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.OnLogOut),
      mergeMap(action => {
        this.tokenStorage.clear()
        this.sessionStorageService.clear()
        this.localStorage.clear()
        this.cookieStorage.deleteCookie('token')
        return of(this.authService.signOut()).pipe(
          map(() => userActions.OnLogOutSuccess({ payload: true })),
          catchError(error => of(userActions.ErrorAction({ payload: error })))
        )
      })
    )
  )

  private setAnalyticsPropertiesForUser(user: ICnectUser) {
    // Set user properties
    const userAnalyticsProperties = pick(user, 'customerKey', 'userType')
    const newKeys = Object.keys(userAnalyticsProperties).reduce((acc, curr) => ({ ...acc, [curr]: snakeCase(curr) }), {})
    const analyticsProperties = renameKeys(userAnalyticsProperties, newKeys)
    return this.analyticsService.setUserProperties(analyticsProperties)
  }
}
