import { Injectable } from '@angular/core'
import {
  BaseCustomerConfigProvider,
  CustomerPartitionConfigProvider,
  ICustomerConfig,
  ICustomerPartitionConfig,
  ICustomerPartitionConfigProvider,
} from '@engineering11/customer-web'
import { Store } from '@ngrx/store'
import { environment } from '../../environments/environment'
import { selectors } from '../store/selectors'

@Injectable({ providedIn: 'root' })
export class AdminCustomerConfigProvider extends BaseCustomerConfigProvider {
  constructor(private store: Store) {
    super()
  }
  get config(): ICustomerConfig {
    return {
      baseUrl: environment.services.customer,
      token$: this.store.select(selectors.getToken),
      customerKey$: this.store.select(selectors.getCustomerKey$),
    }
  }
}

@Injectable({ providedIn: 'root' })
export class AdminCustomerPartitionConfigProvider extends CustomerPartitionConfigProvider implements ICustomerPartitionConfigProvider {
  get config(): ICustomerPartitionConfig {
    return {
      listOfPartitionsUrl: '/company/partition-tabs',
      basePartitionPartial: '/apps',
    }
  }
}
