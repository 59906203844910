import { Injectable } from '@angular/core'
import { BaseNotificationConfigProvider, INotificationsConfig } from '@engineering11/notifications-web'
import { E11Logger } from '@engineering11/web-api-error'
import { Store } from '@ngrx/store'
import { environment } from '../../environments/environment'
import { selectors } from '../store/selectors'

@Injectable({ providedIn: 'root' })
export class NotificationConfigProvider extends BaseNotificationConfigProvider {
  constructor(private store: Store, private logger: E11Logger) {
    super()
  }

  get config(): INotificationsConfig {
    return {
      userId: this.store.select(selectors.getCurrentUserId),
      notificationsBaseURL: environment.services.notifications,
      token$: this.store.select(selectors.getToken),
    }
  }
}
