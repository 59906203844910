import { ADMIN_ROUTES } from '@admin/app/services/admin-navigation.service'
import { selectors } from '@admin/app/store/selectors'
import { Component, OnInit } from '@angular/core'
import { IUser } from '@engineering11/user-shared'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { userActions } from 'shared-lib'

@Component({
  selector: 'profile-dropdown',
  templateUrl: 'profile-dropdown.component.html',
})
export class ProfileDropdownComponent implements OnInit {
  ADMIN_ROUTES = ADMIN_ROUTES
  dropDownOpen = false
  currentUser$: Observable<IUser | null> = this.store.select(selectors.getCurrentUser)
  constructor(private store: Store) {}

  ngOnInit() {}

  signOut() {
    this.store.dispatch(userActions.OnLogOut())
  }
}
