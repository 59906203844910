import { ICandidateUser } from '@cnect/user-shared'
import { decodeUserJwt } from '@engineering11/auth-web'
import { Timestamp } from '@engineering11/types'
import { IUser, userSelectors as selectors } from '@engineering11/user-web'
import { createSelector } from '@ngrx/store'
import jwt_decode from 'jwt-decode'
import { IE11Token } from '../../model/token.model'
import { _candidateOrError } from './user.util'

export const getCurrentUserCandidate = createSelector(selectors.getCurrentUser, (user: Timestamp<IUser> | null) => _candidateOrError(user))
export const getCandidateProfileId = createSelector(getCurrentUserCandidate, (user: ICandidateUser | null) => user?.candidateProfileId)
export const getTokenDecoded = createSelector(selectors.getToken, (token: string | null) => (token ? decodeUserJwt(token) : null))
export const getCurrentTokenDecoded = createSelector(selectors.getCurrentToken, token => (token ? (jwt_decode(token) as IE11Token) : null))
export const getCurrentRoles = createSelector(getCurrentTokenDecoded, (token: IE11Token | null) => token?.roles)

export const userSelectors = {
  ...selectors,
  getCurrentUserCandidate,
  getCandidateProfileId,
  getTokenDecoded,
  getCurrentTokenDecoded,
  getCurrentRoles,
}
