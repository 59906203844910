import { Injectable } from '@angular/core'
import { FilesService, IFileReceipt } from '@engineering11/files-web'
import { AtLeast } from '@engineering11/types'
import { INotificationMessage } from '@engineering11/ui-lib/e11-notifications'
import { stripUndefined, uuid } from '@engineering11/utility'
import { E11ErrorHandlerService, E11Logger } from '@engineering11/web-api-error'
import { NotificationHelper } from '@engineering11/web-ui-helpers'
import { ComponentStore } from '@ngrx/component-store'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { filter, finalize, map, Observable, of, switchMap } from 'rxjs'
import { IPortfolio, IPortfolioFile, PortfolioItemType } from '../../model/portfolio.model'
import { FileUploadService, IFileUploadResponse } from '../../service/file-upload.service'
import { MAX_PORTFOLIO_FILE_SIZE } from './portfolio-constants'
import { tapResponse } from '@ngrx/operators'

export interface IPortfolioStore extends EntityState<IPortfolio> {
  loaded: boolean
  itemLoaded: boolean
  signingFiles: boolean
  lastPortfolioInternalChange: Date | null // This is a way to know when the state changed internally so we can emit out the new portfolio
}

export const portfolioAdapter: EntityAdapter<IPortfolio> = createEntityAdapter<IPortfolio>({
  selectId: portfolio => portfolio.id,
})

const portfolioSelectors = portfolioAdapter.getSelectors()

const initialState: IPortfolioStore = portfolioAdapter.getInitialState({
  loaded: false,
  itemLoaded: true,
  signingFiles: false,
  lastPortfolioInternalChange: null,
})

@Injectable({
  providedIn: 'root',
})
export class PortfolioStore extends ComponentStore<IPortfolioStore> {
  constructor(
    private errorHandler: E11ErrorHandlerService,
    private notificationHelper: NotificationHelper,
    private filesService: FilesService,
    private logger: E11Logger,
    private uploadService: FileUploadService
  ) {
    super(initialState)
  }

  // SELECTORS
  readonly getState = this.select(s => s)
  readonly portfolio$ = this.select(s => portfolioSelectors.selectAll(s))
  readonly lastPortfolioInternalChange$ = this.select(s => s.lastPortfolioInternalChange)
  readonly itemLoaded$ = this.select(s => s.itemLoaded)
  readonly signingFiles$ = this.select(s => s.signingFiles)

  // TODO: Consider using a selector to filter the ui type of portfolio item

  // EFFECTS
  // TODO: Review effects - consider using tapResponse and handle errors

  readonly signFiles = this.effect((portfolioFiles$: Observable<IPortfolioFile[]>) =>
    portfolioFiles$.pipe(
      map(portfolioFiles => portfolioFiles.filter(p => !p.file.signedURL)), // Only sign portfolio items that have not yet been signed
      filter(portfolioFiles => portfolioFiles.length > 0),
      switchMap(async portfolioFiles => {
        this.onSigningFiles(true)
        const files: IFileReceipt[] = portfolioFiles.map((item: IPortfolioFile) => item.file)
        const thumbFiles = portfolioFiles.filter((item: IPortfolioFile) => item.thumb?.id).map(item => item.thumb!)

        files.push(...thumbFiles)
        const signedFiles = await this.filesService.signedURLs(files)

        this.logger.log('signFiles - signedFiles:', signedFiles)

        const addSignedUrls = (item: IPortfolioFile): IPortfolioFile => {
          const signedFile = signedFiles.find(f => f.id === item.file.id) ?? item.file
          const signedThumb = item.thumb?.id ? signedFiles.find(f => f.id === item.thumb!.id) ?? item.thumb : undefined
          return stripUndefined({ ...item, file: signedFile, thumb: signedThumb })
        }

        const signedPortfolio = portfolioFiles.map(addSignedUrls)

        this.logger.log('signFiles - signedPortfolio', signedPortfolio)
        return this.onPortfolioItemsSigned(signedPortfolio)
      })
    )
  )

  readonly uploadPortfolioFiles = this.effect((files$: Observable<File[]>) =>
    files$.pipe(
      switchMap(async requestedFiles => {
        this.setItemLoaded(false)
        let uploadedFiles: IFileUploadResponse[]

        try {
          uploadedFiles = await this.uploadService.uploadFiles({
            files: requestedFiles,
            validation: { maxSize: MAX_PORTFOLIO_FILE_SIZE },
          })
        } catch (err: any) {
          this.errorHandler.handleError(err)
          this.setItemLoaded(true)
          return
        }

        const newPortfolioItems: IPortfolioFile[] = uploadedFiles!.map((file, index) => {
          const item: IPortfolioFile = {
            id: uuid(),
            itemType: PortfolioItemType.FileUpload,
            file: { ...file.fileReceipt, signedURL: undefined },
          }

          if (file.thumbReceipt && file.thumbReceipt.id) item.thumb = file.thumbReceipt
          return item
        })

        this.notificationHelper.popNotification(fileUploadSuccessNotification)
        this.onPortfolioItemsAdded(newPortfolioItems)
      })
    )
  )

  readonly deletePortfolioFile = this.effect((portfolioFile$: Observable<IPortfolioFile>) =>
    portfolioFile$.pipe(
      switchMap(item => {
        // File deletion happens automatically on the back end via document listeners
        return of().pipe(
          tapResponse(
            res => this.notificationHelper.popNotification(deleteSuccessNotification),
            (error: Error) => this.errorHandler.handleError(error, { alertUser: true })
          ),
          finalize(() => this.onPortfolioItemDeleted(item.id))
        )
      })
    )
  )

  readonly deletePortfolioFiles = this.effect((portfolioFiles$: Observable<IPortfolioFile[]>) =>
    portfolioFiles$.pipe(
      switchMap(async files => {
        // File deletion happens automatically on the back end via document listeners
        this.notificationHelper.popNotification(clearSuccessNotification)
        this.onPortfolioItemsDeleted(files.map(file => file.id))
      })
    )
  )

  // UPDATERS

  readonly setAllPortfolio = this.updater((state, portfolio: IPortfolio[]) => {
    const portfolioEntities = state.entities
    // To prevent signed URLs from being cleared as portfolios are updated on the parent
    const portfolioToSet: IPortfolio[] = portfolio.map(p =>
      preserveSignedUrl(p, portfolioEntities[p.id]?.file?.signedURL, portfolioEntities[p.id]?.thumb?.signedURL)
    )
    return portfolioAdapter.setAll(portfolioToSet, { ...state, loaded: true })
  })

  readonly setItemLoaded = this.updater((state, itemLoaded: boolean) => ({
    ...state,
    itemLoaded: itemLoaded,
  }))

  readonly onPortfolioItemsAdded = this.updater((state, portfolio: IPortfolio[]) =>
    portfolioAdapter.addMany(portfolio, { ...state, lastPortfolioInternalChange: new Date(), itemLoaded: true })
  )
  readonly onPortfolioItemUpdated = this.updater((state, portfolio: AtLeast<IPortfolio, 'id'>) =>
    portfolioAdapter.updateOne(
      { id: portfolio.id, changes: portfolio },
      {
        ...state,
        lastPortfolioInternalChange: new Date(),
      }
    )
  )
  readonly onPortfolioItemDeleted = this.updater((state, id: string) =>
    portfolioAdapter.removeOne(id, { ...state, lastPortfolioInternalChange: new Date() })
  )
  readonly onPortfolioItemsDeleted = this.updater((state, ids: string[]) =>
    portfolioAdapter.removeMany(ids, { ...state, lastPortfolioInternalChange: new Date() })
  )

  private readonly onPortfolioItemsSigned = this.updater((state, portfolio: IPortfolioFile[]) =>
    portfolioAdapter.setMany(portfolio, { ...state, signingFiles: false })
  )

  private readonly onSigningFiles = this.updater((state, signingFiles: boolean) => ({ ...state, signingFiles }))
}

const fileUploadSuccessNotification: INotificationMessage = {
  title: 'Success!',
  message: 'You added a spiffy new portfolio item! Look at you go! :)',
  type: 'success',
  autoClose: true,
  dismissOnRouteChange: true,
}

const deleteSuccessNotification: INotificationMessage = {
  title: 'Success!',
  message: 'Item deleted successfully',
  type: 'success',
  autoClose: true,
  dismissOnRouteChange: true,
}

const clearSuccessNotification: INotificationMessage = {
  title: 'Success!',
  message: 'Items cleared successfully',
  type: 'success',
  autoClose: true,
  dismissOnRouteChange: true,
}

function preserveSignedUrl(portfolio: IPortfolio, signedURL?: string, signedThumb?: string) {
  if (!portfolio.file) return portfolio
  const preservedPortfolio = { ...portfolio, file: { ...portfolio.file!, signedURL } }
  if (portfolio.thumb) preservedPortfolio.thumb = { ...portfolio.thumb!, signedURL: signedThumb }
  return preservedPortfolio
}
