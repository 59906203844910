import { CustomerPermissions } from '@engineering11/customer-web'

export enum Permission {
  /** Currently, if you can list templates, you can create them */
  ManageJobTemplates = 'jobs/manage-job-templates',
  SearchJobProfiles = 'jobs/search-job-profiles',
  ManageJobs = 'jobs/manage-jobs',
  ManageCompanySettings = 'company/manage-company-settings',
  ManageAllCustomerUsers = 'user/manage-all-customer-users',
  ManageEmployees = 'user/manage-employees',
  /**
   * Below permissions whether the user can access these features. Some individual items may still be
   * hidden based on other permissions
   */
  ViewApplications = 'applications/view-applications',
  ViewPromotions = 'jobs-integration/view-promotions',
  EditPromotions = 'jobs-integration/edit-promotions',
  ViewJobs = 'jobs/view-jobs',
  ViewDispositionCodes = 'jobs/view-disposition-codes',
  ManageApplicationShares = 'jobs/manage-application-shares',
  ViewCandidatePool = 'candidate-pool/view-candidate-pool',
  EditCandidatePool = 'candidate-pool/edit-candidate-pool',
  ManageCandidatePoolShares = 'candidate-pool/manage-candidate-pool-shares',
  ViewTenantPool = 'candidate-pool/view-tenant-pool',
  EditTenantPool = 'candidate-pool/edit-tenant-pool',
}

export enum JobAutomationPermissions {
  EditAutomationMessage = 'jobs-automation/edit-automation-message',
  ManageAutomation = 'jobs-automation/manage-automation', // Company Admin
  PlatformAdminManageAutomation = 'jobs-automation/platform-admin-manage-automation', // System Admin
}

/**
 * All recognised permissions should be noted in this type
 */
export type AllPermissions = Permission | CustomerPermissions | JobAutomationPermissions

export const allPermissions: AllPermissions[] = [
  ...Object.values(Permission),
  ...Object.values(JobAutomationPermissions),
  ...Object.values(CustomerPermissions),
]
