import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { FilesService, IFileReceipt } from '@engineering11/files-web'
import { isNotNil } from '@engineering11/utility'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import { ICnectUser } from '../../model/interfaces'
import { sharedSelectors } from '../../store'

export const PROFILE_PIC_FOLDER = 'profile'
export enum FILE_MODE {
  URL = 'url',
  RECEIPT = 'receipt',
}
@Component({
  selector: 'photo-edit',
  templateUrl: './photo-edit.component.html',
  styleUrls: ['./photo-edit.component.scss'],
})
export class PhotoEditComponent implements OnInit, OnDestroy {
  @Input()
  currentPhotoUrl?: string | null

  @Input()
  currentPhotoReceipt?: IFileReceipt | null

  @Input()
  mode = FILE_MODE.URL

  @Output()
  newPhotoUrl = new EventEmitter<string>()

  @Output()
  newPhotoReceipt = new EventEmitter<IFileReceipt>()

  @Output()
  removePhoto = new EventEmitter()

  @ViewChild('fileInput')
  photoInput!: any

  loading: boolean = false

  user?: ICnectUser

  destroy$: Subject<boolean> = new Subject<boolean>()

  constructor(private store: Store, private filesService: FilesService) {}

  ngOnInit(): void {
    if (this.mode === FILE_MODE.RECEIPT) {
      this.currentPhotoUrl = this.currentPhotoReceipt?.url
    }

    this.store
      .select(sharedSelectors.getCurrentUser)
      .pipe(takeUntil(this.destroy$), filter(isNotNil))
      .subscribe(user => (this.user = user))
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  async addNewPhoto(event: any) {
    if (!this.user) return

    if (event.target.files && event.target.files?.length) {
      this.loading = true
      const file: File = event.target.files[0]
      await this.uploadNewFile(file)
      await this.deleteCurrentPhotoFile() // file cleanup - no need to emit since new photo will overwrite

      this.loading = false
    }

    this.photoInput.nativeElement.value = '' // Needed so that uploading the same photo after removing it does not bug out
  }

  async removeCurrentPhoto() {
    await this.deleteCurrentPhotoFile()
    this.removePhoto.emit()
  }

  private async uploadNewFile(file: File) {
    if (!this.user) return
    if (this.mode === FILE_MODE.URL) {
      const receipt = await this.filesService.uploadPublicFile(file, PROFILE_PIC_FOLDER)
      this.newPhotoUrl.emit(receipt.url)
    } else {
      const result = await this.filesService.upload(file, this.user.customerKey, this.user.id, true)
      this.newPhotoReceipt.emit(result)
    }
  }

  private async deleteCurrentPhotoFile() {
    this.loading = true
    if (this.mode === FILE_MODE.URL && this.currentPhotoUrl) {
      // Don't delete the storage file so applications still have the file.
      // This is a temp fix until we copy the storage files when the application
      // is submitted. This should be done in the rest api.
      await this.filesService.deletePublicFile(this.currentPhotoUrl)
    }
    // File deletion using receipts happens automatically

    this.currentPhotoUrl = undefined
    this.currentPhotoReceipt = undefined
    //}
    this.loading = false
  }
}
