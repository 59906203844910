import { environment } from '@admin/environments/environment'
import { Injectable } from '@angular/core'
import { ALL_ROLES, roleToSelectOption } from '@employer/app/role.constants'
import { BasePlatformAdminConfigProvider, IPlatformAdminConfig } from '@engineering11/platform-admin-web'
import { IMultiSelectOption } from '@engineering11/ui-lib/e11-multi-select'
import { E11Logger } from '@engineering11/web-api-error'
import { Store } from '@ngrx/store'
import { selectors } from '../store/selectors'

@Injectable({ providedIn: 'root' })
export class SdkAdminConfigProvider extends BasePlatformAdminConfigProvider {
  constructor(private store: Store, private logger: E11Logger) {
    super()
  }
  get config(): IPlatformAdminConfig {
    const UserRolesSelector: IMultiSelectOption[] = ALL_ROLES.map(role => roleToSelectOption(role))
    return {
      baseRoute: '/sdk',
      authTenantId: environment.tenantId,
      token$: this.store.select(selectors.getToken),
      userConfig: { b2bRoles: UserRolesSelector, b2cRoles: [] },
    }
  }
}
