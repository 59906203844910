import { inject, Injectable } from '@angular/core'
import { IAuthService } from '@engineering11/auth-web'
import { BaseUserConfigProvider, IUserConfig } from '@engineering11/user-web'
import { Store } from '@ngrx/store'
import { environment } from '../../environments/environment'
import { selectors } from '../store/selectors'

@Injectable({ providedIn: 'root' })
export class UserConfigProvider extends BaseUserConfigProvider {
  authService = inject(IAuthService)
  store = inject(Store)

  get config(): IUserConfig {
    return {
      userBaseURL: environment.services.user,
      token$: this.store.select(selectors.getCurrentToken),
    }
  }
}
