import { Injectable } from '@angular/core'
import { PermissionStore } from '@engineering11/access-web'
import { BasePaymentConfigurationProvider, IPaymentMappingConfig } from '@engineering11/payment-web'
import { isNotNil } from '@engineering11/utility'
import { E11Logger } from '@engineering11/web-api-error'
import { Store } from '@ngrx/store'
import { filter, map, of } from 'rxjs'
import { environment } from '../../environments/environment'
import { selectors } from '../store/selectors'

@Injectable({ providedIn: 'root' })
export class PaymentConfigProvider extends BasePaymentConfigurationProvider {
  constructor(private store: Store, private permissionStore: PermissionStore, private logger: E11Logger) {
    super()
  }
  get config(): IPaymentMappingConfig {
    return {
      paymentsBaseURL: environment.services.product,
      userId$: this.store.select(selectors.getCurrentUserId),
      token$: this.store.select(selectors.getToken),
      customerKey$: this.store.select(selectors.getCustomerKey$),
      customerBillingEmail$: of(undefined),
      mapsApiKey: environment.firebaseConfig.apiKey,
      productPermissions$: this.permissionStore.permissibleRolePermissions$.pipe(
        filter(isNotNil),
        map(m => m.permissibleRolePermissions)
      ),
    }
  }
}
